"use strict";
import {
  PoolPosition,
  PositionStatus,
  ProtocolVersion,
  Pool as RestPool
} from "@uniswap/client-pools/dist/pools/v1/types_pb";
import { CurrencyAmount, Percent, Token } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { FeeAmount, Pool as V3Pool, Position as V3Position } from "@uniswap/v3-sdk";
import { Pool as V4Pool, Position as V4Position } from "@uniswap/v4-sdk";
import { getPriceOrderingFromPositionForUI } from "components/PositionListItem";
import { ZERO_ADDRESS } from "constants/misc";
import useIsTickAtLimit from "hooks/useIsTickAtLimit";
import JSBI from "jsbi";
import { useMemo } from "react";
import { useAppSelector } from "state/hooks";
import { Bound } from "state/mint/v3/actions";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import { ProtocolItems } from "uniswap/src/data/tradingApi/__generated__";
import { useUSDCPrice } from "uniswap/src/features/transactions/swap/hooks/useUSDCPrice";
import { NumberType, useFormatter } from "utils/formatNumbers";
export function getProtocolVersionLabel(version) {
  switch (version) {
    case ProtocolVersion.V2:
      return "V2";
    case ProtocolVersion.V3:
      return "V3";
    case ProtocolVersion.V4:
      return "V4";
  }
  return void 0;
}
export function getProtocolItems(version) {
  switch (version) {
    case ProtocolVersion.V2:
      return ProtocolItems.V2;
    case ProtocolVersion.V3:
      return ProtocolItems.V3;
    case ProtocolVersion.V4:
      return ProtocolItems.V4;
  }
  return void 0;
}
export function getProtocolStatusLabel(status, t) {
  switch (status) {
    case PositionStatus.IN_RANGE:
      return t("common.withinRange");
    case PositionStatus.OUT_OF_RANGE:
      return t("common.outOfRange");
    case PositionStatus.CLOSED:
      return t("common.closed");
  }
  return void 0;
}
export function parseProtocolVersion(version) {
  switch (version?.toLowerCase()) {
    case "v2":
      return ProtocolVersion.V2;
    case "v3":
      return ProtocolVersion.V3;
    case "v4":
      return ProtocolVersion.V4;
    default:
      return void 0;
  }
}
export function parseV3FeeTier(feeTier) {
  const parsedFee = parseInt(feeTier || "");
  return parsedFee in FeeAmount ? parsedFee : void 0;
}
export function getPoolFromRest({
  pool,
  token0,
  token1,
  protocolVersion,
  hooks
}) {
  if (!pool || !token0 || !token1) {
    return void 0;
  }
  if (pool instanceof RestPool) {
    if (protocolVersion === ProtocolVersion.V3) {
      return new V3Pool(token0, token1, pool.fee, pool.sqrtPriceX96, pool.liquidity, pool.tick);
    }
    return new V4Pool(
      token0,
      token1,
      pool.fee,
      pool.tickSpacing,
      hooks || "",
      pool.sqrtPriceX96,
      pool.liquidity,
      pool.tick
    );
  }
  if (pool instanceof PoolPosition) {
    if (protocolVersion === ProtocolVersion.V3) {
      const feeTier = parseV3FeeTier(pool.feeTier);
      if (feeTier) {
        return new V3Pool(token0, token1, feeTier, pool.currentPrice, pool.liquidity, parseInt(pool.currentTick));
      }
    }
    return new V4Pool(
      token0,
      token1,
      parseInt(pool.feeTier),
      parseInt(pool.tickSpacing),
      hooks || "",
      pool.currentPrice,
      pool.liquidity,
      parseInt(pool.currentTick)
    );
  }
  return void 0;
}
function parseRestToken(token) {
  if (!token) {
    return void 0;
  }
  if (token.address === ZERO_ADDRESS) {
    return nativeOnChain(token.chainId);
  }
  return new Token(token.chainId, token.address, token.decimals, token.symbol);
}
export function getPairFromRest({
  pair,
  token0,
  token1
}) {
  if (!pair) {
    return void 0;
  }
  return new Pair(
    CurrencyAmount.fromRawAmount(token0, pair.reserve0),
    CurrencyAmount.fromRawAmount(token1, pair.reserve1)
  );
}
export function parseRestPosition(position) {
  if (position?.position.case === "v2Pair") {
    const v2PairPosition = position.position.value;
    const token0 = parseRestToken(v2PairPosition.token0);
    const token1 = parseRestToken(v2PairPosition.token1);
    const liquidityToken = parseRestToken(v2PairPosition.liquidityToken);
    if (!token0 || !token1 || !liquidityToken) {
      return void 0;
    }
    const pair = getPairFromRest({ pair: position.position.value, token0, token1 });
    return {
      status: position.status,
      version: ProtocolVersion.V2,
      pair,
      liquidityToken,
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v2PairPosition.liquidity0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v2PairPosition.liquidity1),
      totalSupply: CurrencyAmount.fromRawAmount(liquidityToken, v2PairPosition.totalSupply),
      liquidityAmount: CurrencyAmount.fromRawAmount(liquidityToken, v2PairPosition.liquidity),
      v4hook: void 0,
      feeTier: void 0
    };
  } else if (position?.position.case === "v3Position") {
    const v3Position = position.position.value;
    const token0 = parseRestToken(v3Position.token0);
    const token1 = parseRestToken(v3Position.token1);
    if (!token0 || !token1) {
      return void 0;
    }
    const pool = getPoolFromRest({ pool: position.position.value, token0, token1, protocolVersion: ProtocolVersion.V3 });
    const sdkPosition = pool ? new V3Position({
      pool,
      liquidity: v3Position.liquidity,
      tickLower: Number(v3Position.tickLower),
      tickUpper: Number(v3Position.tickUpper)
    }) : void 0;
    return {
      status: position.status,
      feeTier: parseV3FeeTier(v3Position.feeTier),
      version: ProtocolVersion.V3,
      pool,
      position: sdkPosition,
      tickLower: v3Position.tickLower,
      tickUpper: v3Position.tickUpper,
      tickSpacing: Number(v3Position.tickSpacing),
      liquidity: v3Position.liquidity,
      tokenId: v3Position.tokenId,
      token0UncollectedFees: v3Position.token0UncollectedFees,
      token1UncollectedFees: v3Position.token1UncollectedFees,
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v3Position.amount0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v3Position.amount1),
      v4hook: void 0
    };
  } else if (position?.position.case === "v4Position") {
    const v4Position = position.position.value.poolPosition;
    const token0 = parseRestToken(v4Position?.token0);
    const token1 = parseRestToken(v4Position?.token1);
    if (!v4Position || !token0 || !token1) {
      return void 0;
    }
    const hook = position.position.value.hooks[0]?.address;
    const pool = getPoolFromRest({ pool: v4Position, token0, token1, hooks: hook, protocolVersion: ProtocolVersion.V4 });
    const sdkPosition = pool ? new V4Position({
      pool,
      liquidity: v4Position.liquidity,
      tickLower: Number(v4Position.tickLower),
      tickUpper: Number(v4Position.tickUpper)
    }) : void 0;
    return {
      status: position.status,
      feeTier: v4Position?.feeTier,
      version: ProtocolVersion.V4,
      position: sdkPosition,
      pool,
      v4hook: hook,
      tokenId: v4Position.tokenId,
      tickLower: v4Position?.tickLower,
      tickUpper: v4Position?.tickUpper,
      tickSpacing: Number(v4Position?.tickSpacing),
      currency0Amount: CurrencyAmount.fromRawAmount(token0, v4Position?.amount0 ?? 0),
      currency1Amount: CurrencyAmount.fromRawAmount(token1, v4Position?.amount1 ?? 0),
      token0UncollectedFees: v4Position.token0UncollectedFees,
      token1UncollectedFees: v4Position.token1UncollectedFees,
      liquidity: v4Position.liquidity
    };
  } else {
    return void 0;
  }
}
export function useModalLiquidityPositionInfo() {
  const modalState = useAppSelector((state) => state.application.openModal);
  return modalState?.initialState;
}
export function useGetPoolTokenPercentage(positionInfo) {
  const { totalSupply, liquidityAmount } = positionInfo ?? {};
  const poolTokenPercentage = useMemo(() => {
    return !!liquidityAmount && !!totalSupply && JSBI.greaterThanOrEqual(totalSupply.quotient, liquidityAmount.quotient) ? new Percent(liquidityAmount.quotient, totalSupply.quotient) : void 0;
  }, [liquidityAmount, totalSupply]);
  return poolTokenPercentage;
}
export function useV3OrV4PositionDerivedInfo(positionInfo) {
  const {
    token0UncollectedFees,
    token1UncollectedFees,
    currency0Amount,
    currency1Amount,
    liquidity,
    tickLower,
    tickUpper
  } = positionInfo ?? {};
  const { price: price0 } = useUSDCPrice(currency0Amount?.currency);
  const { price: price1 } = useUSDCPrice(currency1Amount?.currency);
  const { feeValue0, feeValue1 } = useMemo(() => {
    if (!currency0Amount || !currency1Amount) {
      return {};
    }
    return {
      feeValue0: token0UncollectedFees ? CurrencyAmount.fromRawAmount(currency0Amount.currency, token0UncollectedFees) : void 0,
      feeValue1: token1UncollectedFees ? CurrencyAmount.fromRawAmount(currency1Amount.currency, token1UncollectedFees) : void 0
    };
  }, [currency0Amount, currency1Amount, token0UncollectedFees, token1UncollectedFees]);
  const { fiatFeeValue0, fiatFeeValue1 } = useMemo(() => {
    const amount0 = feeValue0 ? price0?.quote(feeValue0) : void 0;
    const amount1 = feeValue1 ? price1?.quote(feeValue1) : void 0;
    return {
      fiatFeeValue0: amount0,
      fiatFeeValue1: amount1
    };
  }, [price0, price1, feeValue0, feeValue1]);
  const { fiatValue0, fiatValue1 } = useMemo(() => {
    if (!price0 || !price1 || !currency0Amount || !currency1Amount) {
      return {};
    }
    const amount0 = price0.quote(currency0Amount);
    const amount1 = price1.quote(currency1Amount);
    return {
      fiatValue0: amount0,
      fiatValue1: amount1
    };
  }, [price0, price1, currency0Amount, currency1Amount]);
  const priceOrdering = useMemo(() => {
    if (positionInfo?.version !== ProtocolVersion.V3 && positionInfo?.version !== ProtocolVersion.V4 || !positionInfo.position || !liquidity || !tickLower || !tickUpper) {
      return {};
    }
    return getPriceOrderingFromPositionForUI(positionInfo.position);
  }, [liquidity, tickLower, tickUpper, positionInfo]);
  return useMemo(
    () => ({
      fiatFeeValue0,
      fiatFeeValue1,
      fiatValue0,
      fiatValue1,
      priceOrdering,
      feeValue0,
      feeValue1,
      token0CurrentPrice: positionInfo?.version === ProtocolVersion.V3 || positionInfo?.version === ProtocolVersion.V4 ? positionInfo.pool?.token0Price : void 0,
      token1CurrentPrice: positionInfo?.version === ProtocolVersion.V3 || positionInfo?.version === ProtocolVersion.V4 ? positionInfo.pool?.token1Price : void 0
    }),
    [fiatFeeValue0, fiatFeeValue1, fiatValue0, fiatValue1, priceOrdering, feeValue0, feeValue1, positionInfo]
  );
}
export function useGetRangeDisplay({
  token0CurrentPrice,
  token1CurrentPrice,
  priceOrdering,
  pricesInverted,
  feeTier,
  tickLower,
  tickUpper
}) {
  const { formatTickPrice } = useFormatter();
  const { currentPrice, priceLower, priceUpper, base, quote } = calculateInvertedValues({
    token0CurrentPrice,
    token1CurrentPrice,
    ...priceOrdering,
    invert: pricesInverted
  });
  const isTickAtLimit = useIsTickAtLimit(parseV3FeeTier(feeTier), Number(tickLower), Number(tickUpper));
  const minPrice = formatTickPrice({
    price: priceLower,
    atLimit: isTickAtLimit,
    direction: Bound.LOWER,
    numberType: NumberType.TokenTx
  });
  const maxPrice = formatTickPrice({
    price: priceUpper,
    atLimit: isTickAtLimit,
    direction: Bound.UPPER,
    numberType: NumberType.TokenTx
  });
  const tokenASymbol = quote?.symbol;
  const tokenBSymbol = base?.symbol;
  return {
    currentPrice,
    minPrice,
    maxPrice,
    tokenASymbol,
    tokenBSymbol
  };
}
function calculateInvertedValues({
  token0CurrentPrice,
  token1CurrentPrice,
  priceLower,
  priceUpper,
  quote,
  base,
  invert
}) {
  return {
    currentPrice: invert ? token1CurrentPrice : token0CurrentPrice,
    priceUpper: invert ? priceLower?.invert() : priceUpper,
    priceLower: invert ? priceUpper?.invert() : priceLower,
    quote: invert ? base : quote,
    base: invert ? quote : base
  };
}
