"use strict";
import { createSlice } from "@reduxjs/toolkit";
import {
  TransactionType
} from "state/transactions/types";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export const initialState = {};
const localTransactionSlice = createSlice({
  name: "localWebTransactions",
  initialState,
  reducers: {
    addTransaction(transactions, {
      payload: { chainId, hash, ...details }
    }) {
      if (transactions[chainId]?.[hash]) {
        throw Error("Attempted to add existing transaction.");
      }
      const txs = transactions[chainId] ?? {};
      txs[hash] = {
        status: TransactionStatus.Pending,
        hash,
        addedTime: Date.now(),
        ...details
      };
      transactions[chainId] = txs;
    },
    clearAllTransactions(transactions, { payload: { chainId } }) {
      if (!transactions[chainId]) {
        return;
      }
      transactions[chainId] = {};
    },
    removeTransaction(transactions, { payload: { chainId, hash } }) {
      if (transactions[chainId][hash]) {
        delete transactions[chainId][hash];
      }
    },
    checkedTransaction(transactions, {
      payload: { chainId, hash, blockNumber }
    }) {
      const tx = transactions[chainId]?.[hash];
      if (!tx || tx.status !== TransactionStatus.Pending) {
        return;
      }
      if (!tx.lastCheckedBlockNumber) {
        tx.lastCheckedBlockNumber = blockNumber;
      } else {
        tx.lastCheckedBlockNumber = Math.max(blockNumber, tx.lastCheckedBlockNumber);
      }
    },
    finalizeTransaction(transactions, {
      payload: { chainId, hash, status, info }
    }) {
      const tx = transactions[chainId]?.[hash];
      if (!tx) {
        return;
      }
      transactions[chainId][hash] = {
        ...tx,
        status,
        confirmedTime: Date.now(),
        info: info ?? tx.info
      };
    },
    /* Marks a bridge tx as deposited, without setting it as confirmed in the UI. */
    confirmBridgeDeposit(transactions, {
      payload: { chainId, hash }
    }) {
      const tx = transactions[chainId]?.[hash];
      if (tx?.info.type !== TransactionType.BRIDGE) {
        return;
      }
      tx.info.depositConfirmed = true;
    },
    cancelTransaction(transactions, {
      payload: { chainId, hash, cancelHash }
    }) {
      const tx = transactions[chainId]?.[hash];
      if (tx) {
        delete transactions[chainId]?.[hash];
        transactions[chainId][cancelHash] = {
          ...tx,
          hash: cancelHash,
          cancelled: true
        };
      }
    }
  }
});
export const {
  addTransaction,
  clearAllTransactions,
  checkedTransaction,
  finalizeTransaction,
  removeTransaction,
  cancelTransaction,
  confirmBridgeDeposit
} = localTransactionSlice.actions;
export default localTransactionSlice.reducer;
